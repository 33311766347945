<template>
  <div class="page-container">
    <progress-step :step="page.step"></progress-step>
      <v-card flat class="signature">
        <v-card-subtitle class="p-v-5">Please Sign Your Loan Agreement.</v-card-subtitle>
        <v-card-text class="p-0" id='loan-agreement-container'>
          <iframe :src="eswUrl" class="position-rel width-full height-full no-border"></iframe>
        </v-card-text>
      </v-card>

    <div id="fixed-bottom-block" class="position-block" style="position: fixed;z-index: 1001;bottom: 0; visibility: hidden" >Block</div>

    <v-overlay
      absolute
      :value="overlay"
    >
      <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
    </v-overlay>
    <v-dialog max-width="600px" v-model="disclosure">
      <v-card flat>
        <v-card-title>
          <v-spacer></v-spacer>
          <img height="120px" src="../../assets/icon/warning.png"/>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="f-s-24 bold-f text-center">
          Attention Please
        </v-card-text>
        <v-card-text>
          This is a <b>very expensive</b> form of borrowing. <span class="bold-f">{{portfolio.displayName}}</span> are designed to assist you in
          meeting your short-term borrowing needs and are not intended to be a long-term financial solution.
        </v-card-text>
        <v-card-text>
          <span class="bold-f">{{portfolio.displayName}}</span> never charges any prepayment penalties on partial or complete repayments. Prompt repayment of
          your loan will reduce the costs associated with borrowing and is strongly recommended by <span class="bold-f">{{portfolio.displayName}}</span>.
          <v-icon color="primary" @click="showModal = true">info</v-icon>
        </v-card-text>
      </v-card>
      <v-card
        style="background: transparent;"
        flat
        class="text-center m-t-15"
      >
        <v-btn @click="disclosure=false" icon><v-icon color="white">close</v-icon></v-btn>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500px" v-model="showModal">
      <v-card flat>
        <v-card-title>You have 3 options to pay-off your loan:</v-card-title>
        <v-card-text>
          <span class="bold-f">Option #1</span>: ’Default Payment’ Option, following the payment schedule on the loan agreement.
        </v-card-text>
        <v-card-text>
          <span class="bold-f">Option #2</span>: ‘Pay Down’ Option, adding an amount of $20.00 or more to your scheduled payment so that
          you can pay off the loan faster and reduce the total finance fees. An advanced notice of 3 business days is
          required.
        </v-card-text>
        <v-card-text>
          <span class="bold-f">Option #3</span>: ‘Pay in Full’ Payment Option: You can pay your loan in full without prepayment penalty.
          An advanced notice of 3 business days is required.
        </v-card-text>
        <v-card-text>
          <strong>{{toUppercase(portfolio.displayName)}} STRONGLY RECOMMENDS YOU TO CONSIDER OPTION #2 OR #3.</strong>
        </v-card-text>
      </v-card>
      <v-card
        style="background: transparent;"
        flat
        class="text-center m-t-15"
      >
        <v-btn @click="showModal=false" icon><v-icon color="white">close</v-icon></v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Tool, DataType } from '../../js/core'
import { OriginationApi } from '../../api/application'
import ProgressStep from '../../components/progress/ProgressStep'

const tool = new Tool()
export default {
  components: { ProgressStep },
  data () {
    return {
      page: DataType.PAGES.SIGNATURE,
      portfolio: {},
      disclosure: true,
      showModal: false,
      applicationId: '',
      eswUrl: '',
      overlay: true,
      timer: null
    }
  },
  props: {
    sloth: Object
  },
  methods: {
    toUppercase (str) {
      if (str) {
        return str.toUpperCase()
      }
    },
    onMessageSloth () {
      const _this = this
      this.sloth.client.on('message', function (message) {
        message = message.data
        switch (parseInt(message.ticket)) {
          case DataType.LoanActionEnum.CHANGE_SIGNATURE_STATUS.value: {
            _this.next()
            break
          }
          default:
            break
        }
      })
    },
    next () {
      const stepMessage = {
        currect: DataType.PAGES.SIGNATURE,
        next: DataType.PAGES.SUCCESS
      }
      this.$eventBus.$emit('sendStepToSloth', stepMessage)
      OriginationApi.enteredAndLeft(
        localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        DataType.PAGES.SIGNATURE.step,
        DataType.PAGES.SUCCESS.step,
        localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID))
      this.$router.push(DataType.PAGES[this.page.next].address)
    },
    testForFL () {
      const _this = this
      if (_this.portfolio.displayName === 'First Loan') {
        _this.eswUrl = _this.eswUrl.replace('/document/signature/', '/wap/document/demo/')
      }
    },
    calDynamicSignCardHeight () {
      const fixedPositionBlock = document.getElementById('fixed-bottom-block')
      document.getElementById('loan-agreement-container').style = 'height: {0}px'.format(fixedPositionBlock.getBoundingClientRect().bottom - 56 - 48 - 32)
    }
  },
  destroyed () {
    this.$eventBus.$off('initSloth')
    clearInterval(this.timer)
  },
  async mounted () {
    const _this = this
    _this.$store.commit('setCurrentPage', _this.page)
    const wsid = localStorage.getItem(DataType.COOKIE_KEY.WSID)
    const params = {
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
      wsid: wsid
    }
    if (this.sloth.client !== null) {
      _this.onMessageSloth()
    } else {
      this.$eventBus.$emit('verification')
      this.$eventBus.$on('openSloth', () => {
        _this.onMessageSloth()
      })
    }
    if (tool.isNotEmpty(this.$store.getters.getRedirectUrl)) {
      _this.eswUrl = this.$store.getters.getRedirectUrl
      _this.$eventBus.$emit('sendSignatureStatus')
      _this.overlay = false
    } else {
      await OriginationApi.blackLinkSignature(params, function (result) {
        _this.overlay = false
        _this.eswUrl = result.documentUrl + '?embed=1'
        _this.$eventBus.$emit('sendSignatureStatus')
      }, () => {
        _this.overlay = false
        _this.$router.push('/notification/-100')
      })
    }
    window.onresize = () => {
      _this.calDynamicSignCardHeight()
    }
    _this.portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
    _this.calDynamicSignCardHeight()
  }
}
</script>
<style lang="css">
.signature.container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 112px) !important;
  width: 80%;
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  padding: 20px;
}
.v-application--wrap {
   min-height: unset;
}
</style>
